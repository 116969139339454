import React, { useState } from 'react';
import manny from '../images/coachManny.jpeg';
import gary from '../images/coachGary.jpeg';
import julian from '../images/Julian.jpeg';
import matt from '../images/coachMatt.jpeg';
import ethan from '../images/ethan.jpeg';
import isaac from '../images/isaac.jpeg';
import certificate from '../images/certificate.png';
import { motion as m } from "framer-motion";

const Instructor = ({ name, bio, image, index }) => {
  // Determine the layout based on the index
  const isImageLeft = index % 2 === 0;
  return (
    <div className={`container mx-auto px-4 py-8 flex flex-col md:flex-row items-center ${isImageLeft ? '' : 'md:flex-row-reverse'}`}>
      <div className="md:w-1/2 mt-4 md:mt-0 p-8">
        <img src={image} alt={name} className="w-full rounded-lg" />
      </div>
      <div className="md:w-1/2 mt-4 md:mt-0">
        <h1 className="font-bold text-[30px] underline">{name}</h1>
        <p className="text-lg">{bio}</p>
      </div>
    </div>
  );
};

const Instructors = () => {
  const [showCertificate, setShowCertificate] = useState(false);
  const toggleCertificate = () => {
    setShowCertificate(!showCertificate);
  };

  const instructors = [
    {
      name: 'Manny Marquez',
      bio: 'Manny received his black belt under Ricardo "Franjinha" Miller in 2015, and has since attained the rank of second-degree black belt.'
            + ' Manny grew up on the Central Coast of California in the 805 area. He is the owner and founder of Westmore BJJ. '
            + 'Apart from his dedication to the sport, Manny prioritizes his role as a parent, raising his daughter. He is actively involved in coaching and supporting his team '
            + 'by helping them achieve their goals through  building an inclusive jiu jitsu community.',
      image: manny,
      layout: 'left'
    },
    {
      name: 'Julian Lopez',
      bio: 'Julian is a long-time practitioner, competitor, and student of the art of Jiu-Jitsu. Currently a brown belt, he continues his journey through training, '
            + 'fighting, and studying jiu jitsu. Born and raised on the central coast, Julian'
            + 's Jiu-Jitsu journey began in 2012. He is a father of two young boys, for whom he sets an example and hopes they will also embrace the path of martial arts. '
            + 'Coaching and teaching play significant roles in Julian'
            + 's life, alongside his responsibilities as a father. With a strong vision in mind, he strives to push the art of Jiu-Jitsu forward, positively impacting everyone'
            + 's lives through what they teach and learn, and fostering a sense of community.',
      image: julian,
      layout: 'right'
    },
    {
      name: 'Matt Aanerud',
      bio: 'Matt is currently a brown belt, having received a majority of his coaching and training from Manny Marquez. Matt grew up on the central coast and '
            + 'began a career in law enforcement in 2003 when he attended Allan Hancock police academy. During his law enforcement career Matt has held numerous '
            + 'assignments: including Investigator, SWAT Operator, Range Master, and Defensive Tactics Instructor. Matt enjoys find hiking trails and adventures with '
            + 'his wife Katie, 3 children and dog Harley. Matt also enjoys visiting BJJ gyms across the country while on work trips.',
      image: matt,
      layout: 'left'
    },
    {
      name: 'Gary Santiago',
      bio: 'Gary is a BJJ brown belt and a skilled instructor. He served as a US Army paratrooper from 2000 to 2007 and was an Army combatives instructor during that time. '
            + 'Currently, he is a police officer with the SMPD and also serves as a defensive tactics instructor. Gary is trained in Arcon and is a GST level 1 instructor. '
            + 'In his free time, he enjoys surfing and riding motorcycles. With his expertise in Brazilian Jiu-Jitsu, his military background, and his dedication '
            + 'to law enforcement, Gary Santiago brings a wealth of knowledge and experience to his instruction.',
      image: gary,
      layout: 'right'
    },
    {
      name: 'Isaac McCormack',
      bio: 'Isaac McCormack, originating from the Central Coast, boasts 26 years of wrestling experience across youth to college levels and has dedicated 12 years '
            + 'to coaching in the sport. Transitioning into Brazilian Jiu-Jitsu with his grappling background came naturally, igniting a fresh passion over the last '
            + 'two years. His journey highlights a commitment to excellence and mentorship in combat sports.',
      image: isaac,
      layout: 'left'
    },
    {
      name: 'Ethan Brown',
      bio: 'With a diverse background as a first responder since 2012, Ethan brings a wealth of real-world experience to Westmore BJJ. His career has spanned '
            + 'wildland firefighting, emergency medicine, and law enforcement where he served on the SWAT team as an operator. Since beginning his jiujitsu '
            + 'journey in 2015, he has earned the rank of purple belt and developed a passion for teaching. Ethan is dedicated to transforming beginners into '
            + 'skilled practitioners, emphasizing the development of real-world combat skills. Outside of the gym, he is a devoted husband and father to '
            + 'four daughters.',
      image: ethan,
      layout: 'right'
    },
  ];

  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75, ease: "easeOut" }}
    >
      <div className="flex justify-center pt-28 pb-20">
        <div className="bg-black text-white max-w-6xl rounded-lg pt-8 pl-6 pr-6">
          <div>
            <center>
              <h1 className="font-bold text-[50px]">Our Instructors</h1>
              <button onClick={toggleCertificate} className="block hover:text-yellow focus:text-gray p-4 font-bold rounded-lg">
              IBJJF Black Belt Certificate
              </button>
              {showCertificate && (
                <div style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1000
                }} onClick={toggleCertificate}>
                  <img src={certificate} alt="Certificate" style={{ maxWidth: '90%', maxHeight: '90%', border: '3px solid white' }} />
                </div>
              )}
            </center>
            {instructors.map((instructor, index) => (
            <Instructor key={instructor.name} {...instructor} index={index} />
          ))}
          </div>
        </div>
      </div>
    </m.div>
  );
};

export default Instructors;
